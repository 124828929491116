import React, { memo } from 'react';

import { UUID } from '../../../../../types';
import { CategoryType } from '../../../categoriesTypes';

import { OnSetCheckedIds } from '../../../../../common/hooks/useTableCheckable';

import { IndexTableRow } from '../../../../common/components/tables/IndexTableRow';
import { IndexTableCell } from '../../../../common/components/tables/IndexTableCell';
import { IndexTableRowCheckMark } from '../../../../common/components/tables/IndexTableRowCheckMark';
import { IndexTableCellDate } from '../../../../common/components/tables/IndexTableCellDate';
import { IndexTableCellUserAvatar } from '../../../../common/components/tables/IndexTableCellUserAvatar';
import { IndexTableCellLink } from '../../../../common/components/tables/IndexTableCellLink';

interface CategoriesIndexTableRowProps {
  category: CategoryType;
  checked: boolean;
  onCheck: OnSetCheckedIds;
  onEdit: (itemUuid: UUID) => void;
}

function CategoriesIndexTableRow({
  category,
  checked,
  onCheck,
  onEdit
}: CategoriesIndexTableRowProps) {
  return (
    <IndexTableRow
      scope="category"
      checked={checked}
      itemId={category.id}
      itemUuid={category.uuid}
      onCheck={onCheck}
      onEdit={onEdit}
    >
      <IndexTableCell>{category.name}</IndexTableCell>
      <IndexTableCell>{category.nameEn}</IndexTableCell>
      <IndexTableCell>{category.nameUk}</IndexTableCell>
      <IndexTableCell>{category.order}</IndexTableCell>
      <IndexTableRowCheckMark checked={category.pdfExport} />
      <IndexTableCell>{category.categoriesCount}</IndexTableCell>
      <IndexTableRowCheckMark checked={category.blocked} />
      <IndexTableCellLink scope="sections" item={category.section} />
      <IndexTableCellLink scope="categories" item={category.parent} />
      <IndexTableCellUserAvatar user={category.user} />
      <IndexTableCellDate date={category.createdAt} />
    </IndexTableRow>
  );
}

export default memo<CategoriesIndexTableRowProps>(CategoriesIndexTableRow);
