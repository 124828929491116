import { useCallback } from 'react';
import omit from 'lodash/omit';

import { FilterBadgeProps } from '../types';
import { IconsEnum } from '../../../assets/icons/types';

import { Translate } from '../../Translate';
import { PureIconButtonHelper } from '../../buttons/PureIconButtonHelper';
import { DateFilterTypeConverter } from '../../../utils/DateFilterTypeConverter';

function DateRangeFilterBadge({
  filters,
  i18nLabel,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const handleRemoveFilter = useCallback<() => void>(() => {
    onRemoveFilter(omit(filters, name));
  }, [onRemoveFilter, filters, name]);

  const filterLocalDateRange = DateFilterTypeConverter.toLocalDateRange(
    filters?.[name]
  );

  return filters?.[name] ? (
    <span className="bg-blue-500 bg-opacity-20 dark:text-gray-200 inline-flex items-center m-0.5 px-3 py-1.5 rounded-md text-gray-800 text-sm">
      <Translate id={i18nLabel || name} />: &nbsp;
      {filterLocalDateRange?.gte || '...'}&nbsp;-&nbsp;
      {filterLocalDateRange?.lte || '...'}
      <PureIconButtonHelper
        className="flex-shrink-0 ml-1 -my-1 -mr-2 h-6 w-6 rounded inline-flex items-center justify-center text-gray-800 dark:text-gray-200 hover:bg-gray-500 hover:bg-opacity-20 hover:text-gray-700 dark:hover:bg-opacity-20 dark:hover:bg-white dark:hover:text-white"
        icon={IconsEnum.X}
        iconClassName="h-3.5 w-3.5"
        onClick={handleRemoveFilter}
      />
    </span>
  ) : null;
}

export default DateRangeFilterBadge;
