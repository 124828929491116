import { memo } from 'react';

import { IndexTableCell } from '../IndexTableCell';

import { CheckMark } from '../../../../../helpers/CheckMark';

interface IndexTableRowCheckMarkProps {
  checked: boolean;
}

function IndexTableRowCheckMark({ checked }: IndexTableRowCheckMarkProps) {
  return (
    <IndexTableCell>
      <CheckMark checked={checked} />
    </IndexTableCell>
  );
}

export default memo<IndexTableRowCheckMarkProps>(IndexTableRowCheckMark);
