import isEmpty from 'lodash/isEmpty';

import {
  FetchCategoriesFilters,
  FetchCategoriesFiltersFunc
} from '../../../categoriesTypes';

import { BooleanFilterBadge } from '../../../../../helpers/filterBadges/BooleanFilterBadge';
import { IndexTablePageSubHeader } from '../../../../common/components/headers/IndexTablePageSubHeader';
import { DateRangeFilterBadge } from '../../../../../helpers/filterBadges/DateRangeFilterBadge';

import { formsFields } from '../../../../../locales/keys';

interface CategoriesIndexPageSubHeaderProps {
  categoriesFilters: FetchCategoriesFilters;
  filterCategories: FetchCategoriesFiltersFunc;
}

function CategoriesIndexPageSubHeader({
  categoriesFilters,
  filterCategories
}: CategoriesIndexPageSubHeaderProps) {
  if (isEmpty(categoriesFilters)) {
    return null;
  }

  return (
    <IndexTablePageSubHeader>
      <DateRangeFilterBadge
        filters={categoriesFilters}
        name="createdAt"
        i18nLabel={formsFields.createdAt}
        onRemoveFilter={filterCategories}
      />
      <BooleanFilterBadge
        filters={categoriesFilters}
        name="pdfExport"
        onRemoveFilter={filterCategories}
      />
      <BooleanFilterBadge
        filters={categoriesFilters}
        name="blocked"
        onRemoveFilter={filterCategories}
      />
    </IndexTablePageSubHeader>
  );
}

export default CategoriesIndexPageSubHeader;
