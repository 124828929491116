import { DateFilterType } from '../../types';

import { dateFnsConvert } from '../dateFnsConvert';

class DateFilterTypeConverter {
  static toISODateTimeRange(value: DateFilterType) {
    const gteDate = new Date(value?.gte);
    const lteDate = new Date(value?.lte);

    if (value?.gte) {
      gteDate.setHours(0);
      gteDate.setMinutes(0);
      gteDate.setSeconds(0);
    }

    if (value?.lte) {
      lteDate.setHours(23);
      lteDate.setMinutes(59);
      lteDate.setSeconds(59);
    }

    return value?.gte || value?.lte
      ? {
          gte: value?.gte ? gteDate.toISOString() : undefined,
          lte: value?.lte ? lteDate.toISOString() : undefined
        }
      : undefined;
  }

  static toLocalDateRange(value: DateFilterType) {
    return value?.gte || value?.lte
      ? {
          gte: value?.gte
            ? dateFnsConvert.toDateTimeLocal(value?.gte).split('T')?.[0]
            : undefined,
          lte: value?.lte
            ? dateFnsConvert.toDateTimeLocal(value?.lte).split('T')?.[0]
            : undefined
        }
      : undefined;
  }
}

export default DateFilterTypeConverter;
