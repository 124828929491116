import { memo } from 'react';

import { IconsEnum } from '../../assets/icons/types';

import { Icon } from '../Icon';

interface CheckMarkProps {
  checked?: boolean;
}

function CheckMark({ checked }: CheckMarkProps) {
  return <>{checked ? <Icon icon={IconsEnum.CHECK} /> : null}</>;
}

export default memo(CheckMark);
