import { ClassName, TableViewType } from '../../../../../types';

import { IndexTableCell } from '../IndexTableCell';

import {
  UserAvatarLinkUser,
  UserAvatarLink,
  UserAvatarLinkLinkType
} from '../../../helpers/UserAvatarLink';
import { UserAvatar } from '../../../helpers/UserAvatar';

interface IndexTableCellUserAvatarProps {
  user: UserAvatarLinkUser;
  linkType?: UserAvatarLinkLinkType;
  tdClassName?: ClassName;
  viewType?: TableViewType;
  avatarType?: 'main' | 'xl' | 'xs';
  withoutLink?: boolean;
}

function IndexTableCellUserAvatar({
  user,
  linkType,
  tdClassName,
  viewType,
  avatarType = 'main',
  withoutLink
}: IndexTableCellUserAvatarProps) {
  return (
    <IndexTableCell tdClassName={tdClassName} viewType={viewType}>
      {withoutLink ? (
        <UserAvatar type={avatarType} user={user} />
      ) : (
        <UserAvatarLink
          avatarType={avatarType}
          user={user}
          linkType={linkType}
        />
      )}
    </IndexTableCell>
  );
}

export default IndexTableCellUserAvatar;
