import React, { ReactNode } from 'react';

interface IndexTablePageSubHeaderProps {
  children: ReactNode;
}

function IndexTablePageSubHeader({ children }: IndexTablePageSubHeaderProps) {
  return (
    <div className="px-4">
      <div className="flex flex-wrap -m-0.5">{children}</div>
    </div>
  );
}

export default IndexTablePageSubHeader;
