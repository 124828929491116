import { useEffect, useMemo } from 'react';
import omit from 'lodash/omit';

import { AfterSubmitFunc } from '../../../../../../../types';
import {
  FetchCategoriesClearCategoriesFilters,
  FetchCategoriesFilterCategories,
  FetchCategoriesFilters
} from '../../../../../categoriesTypes';
import { CreateSearchFilterFunc } from '../../../../../../searchFilters/searchFiltersTypes';
import { FilterCategoriesFormData } from '../../FilterCategoriesForm.types';

import { useIndexPageFiltersForm } from '../../../../../../common/hooks/useIndexPageFiltersForm';

import { DateFilterTypeConverter } from '../../../../../../../utils/DateFilterTypeConverter';

import { formsErrors } from '../../../../../../../locales/keys';
import {
  saveFiltersFormFields,
  SearchFiltersScopes
} from '../../../../../../searchFilters/searchFiltersConstants';

interface FilterCategoriesFormOptions {
  afterSubmit: AfterSubmitFunc;
  filterCategories: FetchCategoriesFilterCategories;
  categoriesFilters: FetchCategoriesFilters;
  onClear: FetchCategoriesClearCategoriesFilters;
  onSaveFilters: CreateSearchFilterFunc<FetchCategoriesFilters>;
}

function useFilterCategoriesForm({
  afterSubmit,
  filterCategories,
  categoriesFilters,
  onClear,
  onSaveFilters
}: FilterCategoriesFormOptions) {
  const {
    control,
    errors,
    handleSubmitIndexPageFiltersForm,
    onCancel,
    onGoBack,
    register,
    saveFiltersMode,
    setValue,
    toggleSaveFiltersMode
  } = useIndexPageFiltersForm<FilterCategoriesFormData>({
    defaultValues: {
      createdAt: categoriesFilters.createdAt,
      blocked: false,
      pdfExport: false
    },
    onClear
  });

  const handleFilterCategoriesSubmit = useMemo(
    () =>
      handleSubmitIndexPageFiltersForm({
        dirtyFieldsOnly: false,
        onSubmit: (data: FilterCategoriesFormData) => {
          const cleanDataFields = {
            createdAt: DateFilterTypeConverter.toISODateTimeRange(
              data.createdAt
            )
          };

          saveFiltersMode
            ? onSaveFilters({
                data: {
                  ...categoriesFilters,
                  ...omit(data, saveFiltersFormFields),
                  ...cleanDataFields
                },
                name: data.name,
                scope: SearchFiltersScopes.CATEGORIES_INDEX
              })
            : filterCategories(
                omit(
                  {
                    ...categoriesFilters,
                    ...data,
                    ...cleanDataFields
                  },
                  saveFiltersFormFields
                )
              );
          afterSubmit?.();
        }
      }),
    [
      afterSubmit,
      filterCategories,
      handleSubmitIndexPageFiltersForm,
      onSaveFilters,
      categoriesFilters,
      saveFiltersMode
    ]
  );

  useEffect(() => {
    setValue('blocked', categoriesFilters.blocked);
    setValue(
      'createdAt',
      DateFilterTypeConverter.toLocalDateRange(categoriesFilters.createdAt)
    );
    setValue('pdfExport', categoriesFilters.pdfExport);
    setValue('name', '');
  }, [
    categoriesFilters.blocked,
    categoriesFilters.createdAt,
    categoriesFilters.pdfExport,
    setValue
  ]);

  return {
    control,
    errors,
    handleFilterCategoriesSubmit,
    saveFiltersMode,
    toggleSaveFiltersMode,
    onCancel,
    onGoBack,
    registerFields: {
      registerBlocked: register('blocked'),
      registerPdfExport: register('pdfExport'),
      registerCreatedAt: register('createdAt'),
      registerName: register(
        'name',
        saveFiltersMode
          ? {
              required: formsErrors.required
            }
          : undefined
      )
    }
  };
}

export default useFilterCategoriesForm;
