import React, { Fragment } from 'react';
import isFunction from 'lodash/isFunction';

import { IsFetched, UUID, ErrorMessage } from '../../../../../types';

import { FetchCategoriesSort, CategoriesType } from '../../../categoriesTypes';

import { useTableCheckable } from '../../../../../common/hooks/useTableCheckable';

import { IndexTable } from '../../../../common/components/tables/IndexTable';

import { CategoriesIndexTableBody } from '../CategoriesIndexTableBody';
import { CategoriesIndexTableHeader } from '../CategoriesIndexTableHeader';
import { CategoriesIndexTablePagination } from '../CategoriesIndexTablePagination';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { Loading } from '../../../../../helpers/Loading';
import { NoResults } from '../../../../../helpers/NoResults';

interface CategoriesIndexTableProps {
  categories: CategoriesType;
  categoriesFetched: IsFetched;
  categoriesError: ErrorMessage;
  categoriesIsPlaceholderData: boolean;
  categoriesSort: FetchCategoriesSort;
  categoriesPage: number;
  categoriesLimit: number;
  categoriesTotalCount: number;
  sortCategories: (nextSort: FetchCategoriesSort) => void;
  paginateCategories: (nextPage: number) => void;
  prefetchCategories: ({ nextPage }: { nextPage: number }) => void;
  onCategoryEdit: (sectionUuid: UUID) => void;
}

function CategoriesIndexTable({
  categories,
  categoriesFetched,
  categoriesError,
  categoriesIsPlaceholderData,
  categoriesSort,
  categoriesPage,
  categoriesLimit,
  categoriesTotalCount,
  sortCategories,
  paginateCategories,
  prefetchCategories,
  onCategoryEdit
}: CategoriesIndexTableProps) {
  const { checkedHash, checkedAll, handleSetCheckedIds, handleCheckAll } =
    useTableCheckable({ items: categories });

  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={categoriesError} />
      <Loading loaded={!categoriesIsPlaceholderData} />
      <LoadingSkeleton
        loaded={categoriesIsPlaceholderData || categoriesFetched}
      >
        {categoriesTotalCount === 0 ? (
          <NoResults addErrorClassName="m-4" />
        ) : (
          <IndexTable scope="categories">
            <CategoriesIndexTableHeader
              editable={isFunction(onCategoryEdit)}
              categoriesSort={categoriesSort}
              checkedAll={checkedAll}
              sortCategories={sortCategories}
              onCheckAll={handleCheckAll}
            />
            <CategoriesIndexTableBody
              categories={categories}
              checkedHash={checkedHash}
              onEdit={onCategoryEdit}
              onSetCheckedIds={handleSetCheckedIds}
            />
          </IndexTable>
        )}
      </LoadingSkeleton>
      <CategoriesIndexTablePagination
        categoriesPage={categoriesPage}
        categoriesLimit={categoriesLimit}
        categoriesTotalCount={categoriesTotalCount}
        paginateCategories={paginateCategories}
        prefetchCategories={prefetchCategories}
      />
    </Fragment>
  );
}

export default CategoriesIndexTable;
