import React, { memo } from 'react';

import { ID, UUID } from '../../../../../types';

import { CategoriesType } from '../../../categoriesTypes';

import { CheckedHashItem } from '../../../../../common/hooks/useTableCheckable';

import { IndexTableBody } from '../../../../common/components/tables/IndexTableBody';
import { CategoriesIndexTableRow } from '../CategoriesIndexTableRow';

interface CategoriesIndexTableBodyProps {
  categories: CategoriesType;
  checkedHash: CheckedHashItem;
  onEdit: (itemUuid: UUID) => void;
  onSetCheckedIds: (value: ID) => void;
}

function CategoriesIndexTableBody({
  categories,
  checkedHash,
  onEdit,
  onSetCheckedIds
}: CategoriesIndexTableBodyProps) {
  return (
    <IndexTableBody>
      {categories.map((category) => (
        <CategoriesIndexTableRow
          key={category.uuid}
          category={category}
          checked={checkedHash[category.id]}
          onCheck={onSetCheckedIds}
          onEdit={onEdit}
        />
      ))}
    </IndexTableBody>
  );
}

export default memo<CategoriesIndexTableBodyProps>(CategoriesIndexTableBody);
