import React, { memo } from 'react';

import { PaginationForm } from '../../../../common/forms/PaginationForm';

interface CategoriesIndexTablePaginationProps {
  categoriesPage: number;
  categoriesLimit: number;
  categoriesTotalCount: number;
  paginateCategories: (nextPage: number) => void;
  prefetchCategories: ({ nextPage }: { nextPage: number }) => void;
}

function CategoriesIndexTablePagination({
  categoriesPage,
  categoriesLimit,
  categoriesTotalCount,
  paginateCategories,
  prefetchCategories
}: CategoriesIndexTablePaginationProps) {
  return (
    <div className="px-4">
      <PaginationForm
        page={categoriesPage}
        limit={categoriesLimit}
        totalItemsCount={categoriesTotalCount}
        onPaginate={paginateCategories}
        onPaginateMouseEnter={prefetchCategories}
      />
    </div>
  );
}

export default memo<CategoriesIndexTablePaginationProps>(
  CategoriesIndexTablePagination
);
