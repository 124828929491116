import { ChangeEvent, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import get from 'lodash/get';

import { ClassName, I18nText } from '../../../../../types';

import { RangeInputValueType } from './RangeInputFieldControl.types';

import { InputField } from '../../../InputField';

import { words } from '../../../../../locales/keys';

interface RangeInputFieldControlProps {
  className?: ClassName;
  error?: FieldError;
  i18nFromPlaceholder?: I18nText;
  i18nToPlaceholder?: I18nText;
  name: string;
  onChange: (value: RangeInputValueType) => void;
  type?: string;
  value: RangeInputValueType;
}

function RangeInputFieldControl({
  className,
  error,
  i18nFromPlaceholder,
  i18nToPlaceholder,
  name,
  onChange,
  type,
  value
}: RangeInputFieldControlProps) {
  const handleGteChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      onChange({
        gte: e?.target?.value,
        lte: value?.lte
      }),
    [onChange, value?.lte]
  );

  const handleLteChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      onChange({
        gte: value?.gte,
        lte: e?.target?.value
      }),
    [onChange, value?.gte]
  );

  return (
    <div
      className={
        className ||
        'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0'
      }
    >
      <InputField
        className="flex-1"
        error={get(error, 'gte.message') as string}
        i18nLabel={words.from}
        inputWrapperClassName="mt-1 relative"
        i18nPlaceholder={i18nFromPlaceholder}
        name={`${name}.gte`}
        onChange={handleGteChange}
        type={type}
        value={value?.gte || ''}
        showErrorIcon={false}
      />
      <InputField
        className="flex-1"
        error={get(error, 'lte.message') as string}
        i18nLabel={words.to}
        inputWrapperClassName="mt-1 relative"
        i18nPlaceholder={i18nToPlaceholder}
        name={`${name}.lte`}
        onChange={handleLteChange}
        type={type}
        value={value?.lte || ''}
        showErrorIcon={false}
      />
    </div>
  );
}

export default RangeInputFieldControl;
