import cl from 'classnames';

import { AfterSubmitFunc, IsLoading } from '../../../../../types';
import {
  FetchCategoriesClearCategoriesFilters,
  FetchCategoriesFilterCategories,
  FetchCategoriesFilters
} from '../../../categoriesTypes';
import { CreateSearchFilterFunc } from '../../../../searchFilters/searchFiltersTypes';
import { FilterCategoriesFormData } from './FilterCategoriesForm.types';

import { useFilterCategoriesForm } from './hooks/useFilterCategoriesForm';

import { CheckBoxField } from '../../../../../helpers/FormFields/CheckBoxField';
import { IndexTableFilterForm } from '../../../../common/components/filterForms/IndexTableFilterForm';
import { IndexTableFilterFormField } from '../../../../common/components/filterForms/IndexTableFilterForm/helpers/IndexTableFilterFormField';
import { IndexTableFilterFormFieldGroup } from '../../../../common/components/filterForms/IndexTableFilterForm/helpers/IndexTableFilterFormFieldGroup';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { RangeInputField } from '../../../../../helpers/FormFields/RangeInputField';

import { formsFields, words } from '../../../../../locales/keys';

interface FilterCategoriesFormProps {
  afterSubmit: AfterSubmitFunc;
  categoriesFilters: FetchCategoriesFilters;
  filterCategories: FetchCategoriesFilterCategories;
  isLoading?: IsLoading;
  onClear: FetchCategoriesClearCategoriesFilters;
  onSaveFilters?: CreateSearchFilterFunc<FetchCategoriesFilters>;
}

function FilterCategoriesForm({
  afterSubmit,
  categoriesFilters,
  filterCategories,
  isLoading,
  onClear,
  onSaveFilters
}: FilterCategoriesFormProps) {
  const {
    control,
    errors,
    handleFilterCategoriesSubmit,
    onCancel,
    onGoBack,
    registerFields,
    saveFiltersMode,
    toggleSaveFiltersMode
  } = useFilterCategoriesForm({
    afterSubmit,
    categoriesFilters,
    filterCategories,
    onClear,
    onSaveFilters
  });

  return (
    <IndexTableFilterForm
      i18nCancelText={saveFiltersMode ? words.cancel : words.clear}
      i18nGoBackHeaderText={saveFiltersMode ? words.saveFilter : null}
      i18nSubmitText={saveFiltersMode ? words.saveFilter : words.search}
      isLoading={isLoading}
      onCancel={onCancel}
      onGoBack={onGoBack}
      onSaveFilters={saveFiltersMode ? null : toggleSaveFiltersMode}
      onSubmit={handleFilterCategoriesSubmit}
    >
      {saveFiltersMode ? (
        <div id="save-filter-form" className="py-6 flex-col space-y-6 text-sm">
          <IndexTableFilterFormFieldGroup
            i18nGroupLabel={formsFields.filterName}
          >
            <InputField
              disabled={isLoading}
              error={errors?.name?.message}
              i18nPlaceholder={formsFields.filterName}
              inputWrapperClassName="mt-1 relative"
              labelClassName="font-light uppercase"
              name={registerFields.registerName.name}
              onChange={registerFields.registerName.onChange}
              ref={registerFields.registerName.ref}
              type="text"
            />
          </IndexTableFilterFormFieldGroup>
        </div>
      ) : null}

      <div
        id="filters-group"
        className={cl('py-6 flex flex-col space-y-6 text-sm', {
          hidden: saveFiltersMode
        })}
      >
        <IndexTableFilterFormFieldGroup i18nGroupLabel={formsFields.createdAt}>
          <RangeInputField<FilterCategoriesFormData>
            control={control}
            name="createdAt"
            type="date"
          />
        </IndexTableFilterFormFieldGroup>

        <IndexTableFilterFormField>
          <CheckBoxField
            i18nLabel="forms.fields.pdfExport"
            id="filter_categories_pdf_export"
            name={registerFields.registerPdfExport.name}
            ref={registerFields.registerPdfExport.ref}
            onChange={registerFields.registerPdfExport.onChange}
          />
        </IndexTableFilterFormField>

        <IndexTableFilterFormField>
          <CheckBoxField
            i18nLabel="forms.fields.blocked"
            id="filter_categories_blocked"
            name={registerFields.registerBlocked.name}
            ref={registerFields.registerBlocked.ref}
            onChange={registerFields.registerBlocked.onChange}
          />
        </IndexTableFilterFormField>
      </div>
    </IndexTableFilterForm>
  );
}

export default FilterCategoriesForm;
