import React, { useCallback } from 'react';

import {
  CategoryFields,
  FetchCategoriesFilters,
  FetchCategoriesFiltersFunc
} from '../../../categoriesTypes';

import { IndexTableMainFilter } from '../../../../common/components/filters/IndexTableMainFilter';
import { IndexTablePageHeader } from '../../../../common/components/headers/IndexTablePageHeader';

import { categoriesKeys } from '../../../../../locales/keys';

interface CategoriesIndexPageHeaderProps {
  categoriesFilters: FetchCategoriesFilters;
  filterCategories: FetchCategoriesFiltersFunc;
}

function CategoriesIndexPageHeader({
  categoriesFilters,
  filterCategories
}: CategoriesIndexPageHeaderProps) {
  const handleSearch = useCallback<(name: string) => void>(
    (name: string) => filterCategories({ name: { ilike: name } }),
    [filterCategories]
  );

  return (
    <IndexTablePageHeader actionButtons="Create Category">
      <IndexTableMainFilter
        fieldName={CategoryFields.NAME}
        value={categoriesFilters.name?.ilike}
        i18nPlaceholder={categoriesKeys.search}
        onSearch={handleSearch}
        scope="categories"
      />
    </IndexTablePageHeader>
  );
}

export default CategoriesIndexPageHeader;
