import React from 'react';

import { ClassName, TableViewType } from '../../../../../types';

import { IndexTableCell } from '../IndexTableCell';

import { DateHelper } from '../../../../../helpers/DateHelper';

import { getCustomDateFormat } from '../../../../../utils/getCustomDateFormat';

interface IndexTableCellDateProps {
  date: string;
  customFormat?: string;
  nullValue?: string;
  tdClassName?: ClassName;
  viewType?: TableViewType;
}

function IndexTableCellDate({
  date,
  customFormat,
  nullValue,
  tdClassName,
  viewType
}: IndexTableCellDateProps) {
  return (
    <IndexTableCell viewType={viewType} tdClassName={tdClassName}>
      <DateHelper
        date={date}
        nullValue={nullValue}
        customFormat={customFormat || getCustomDateFormat(date)}
      />
    </IndexTableCell>
  );
}

export default IndexTableCellDate;
