import React, { memo } from 'react';

import { ClassName, TableViewType, UUID } from '../../../../../types';

import { IndexTableCell } from '../IndexTableCell';

import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';

interface IndexTableCellLinkItemWithName {
  uuid: UUID;
  name: string;
}

interface IndexTableCellLinkItemWithLocalizedName {
  uuid: UUID;
  localizedName: string;
}

interface IndexTableCellLinkProps {
  linkClassName?: ClassName;
  divClassName?: ClassName;
  item:
    | IndexTableCellLinkItemWithName
    | IndexTableCellLinkItemWithLocalizedName
    | null;
  scope: string;
  tdClassName?: ClassName;
  slug?: string;
  text?: string;
  viewType?: TableViewType;
}

function IndexTableCellLink({
  linkClassName,
  divClassName,
  item,
  scope,
  tdClassName,
  slug = '',
  text,
  viewType
}: IndexTableCellLinkProps) {
  const linkText =
    text ||
    (item && 'localizedName' in item && item?.localizedName) ||
    (item && 'name' in item && item?.name) ||
    null;

  return (
    <IndexTableCell tdClassName={tdClassName} viewType={viewType}>
      {item ? (
        <div className={divClassName || 'flex flex-wrap'}>
          <NextLinkHelper
            key={item.uuid}
            href={`/${scope}/${item.uuid}/${slug}`}
            className={
              linkClassName ||
              'inline-flex items-center px-2 py-0.5 m-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 dark:text-gray-100 dark:bg-gray-700 dark:bg-opacity-50'
            }
          >
            {linkText}
          </NextLinkHelper>
        </div>
      ) : null}
    </IndexTableCell>
  );
}

export default memo<IndexTableCellLinkProps>(IndexTableCellLink);
