import React, { ReactNode } from 'react';

interface IndexTableFilterFormFieldProps {
  children: ReactNode;
}

function IndexTableFilterFormField({
  children
}: IndexTableFilterFormFieldProps) {
  return (
    <div className="px-4">
      <div className="mt-3 flex flex-col sm:flex-row sm:flex-wrap -mb-2">
        <div className="w-full sm:w-1/2 mb-2">{children}</div>
      </div>
    </div>
  );
}

export default IndexTableFilterFormField;
