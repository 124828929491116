import React, { memo } from 'react';

import { FetchCategoriesSort } from '../../../categoriesTypes';

import {
  CheckedAll,
  OnCheckAll
} from '../../../../../common/hooks/useTableCheckable';

import { IndexTableHeader } from '../../../../common/components/tables/IndexTableHeader';
import { IndexTableHeaderCell } from '../../../../common/components/tables/IndexTableHeaderCell';
import { IndexTableHeaderSortableCell } from '../../../../common/components/tables/IndexTableHeaderSortableCell';

interface CategoriesIndexTableHeaderProps {
  editable: boolean;
  categoriesSort: FetchCategoriesSort;
  checkedAll: CheckedAll;
  sortCategories: (nextSort: FetchCategoriesSort) => void;
  onCheckAll: OnCheckAll;
}

function CategoriesIndexTableHeader({
  editable,
  categoriesSort,
  checkedAll,
  sortCategories,
  onCheckAll
}: CategoriesIndexTableHeaderProps) {
  return (
    <IndexTableHeader
      editable={editable}
      checkedAll={checkedAll}
      onCheckAll={onCheckAll}
      sortableField="id"
      currentSort={categoriesSort}
      sortItems={sortCategories}
    >
      <IndexTableHeaderSortableCell
        i18nText="forms.fields.name"
        sortableField="name"
        currentSort={categoriesSort}
        onSort={sortCategories}
      />
      <IndexTableHeaderSortableCell
        i18nText="forms.fields.nameEn"
        sortableField="nameEn"
        currentSort={categoriesSort}
        onSort={sortCategories}
      />
      <IndexTableHeaderSortableCell
        i18nText="forms.fields.nameUk"
        sortableField="nameUk"
        currentSort={categoriesSort}
        onSort={sortCategories}
      />
      <IndexTableHeaderSortableCell
        i18nText="forms.fields.order"
        sortableField="order"
        currentSort={categoriesSort}
        onSort={sortCategories}
      />
      <IndexTableHeaderCell i18nText="forms.fields.pdfExport" />
      <IndexTableHeaderSortableCell
        i18nText="forms.fields.categoriesCount"
        sortableField="categoriesCount"
        currentSort={categoriesSort}
        onSort={sortCategories}
      />
      <IndexTableHeaderCell i18nText="forms.fields.blocked" />
      <IndexTableHeaderCell i18nText="forms.fields.section" />
      <IndexTableHeaderCell i18nText="forms.fields.parentCategory" />
      <IndexTableHeaderCell i18nText="forms.fields.user" />
      <IndexTableHeaderSortableCell
        i18nText="forms.fields.createdAt"
        sortableField="createdAt"
        currentSort={categoriesSort}
        onSort={sortCategories}
      />
    </IndexTableHeader>
  );
}

export default memo<CategoriesIndexTableHeaderProps>(
  CategoriesIndexTableHeader
);
